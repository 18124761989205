import cn from 'classnames';
import dynamic from 'next/dynamic';
import { useTranslation } from 'hooks';

const Spinner = dynamic(() => import('./Spinner'));

const LoaderInline: React.FC<React.HTMLAttributes<HTMLSpanElement>> = ({
  className,
  children,
  ...props
}) => {
  const { t } = useTranslation(['common']);

  return (
    <span className={cn('loader__inline', className)} {...props}>
      <Spinner size="small" />
      {children ? children : `${t('loading')}...`}
    </span>
  );
};

export default LoaderInline;
